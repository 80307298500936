<template>
    <div class="flex gap-x-0.5 my-auto">
        <img
            v-for="n in activeStar"
            :key="n"
            src="@/assets/icons/fill-star.svg"
            class="w-3 h-3"
        >
        <img
            v-for="n in (5 - activeStar)"
            :key="n"
            src="@/assets/icons/star.svg"
            class="w-3 h-3"
        >
    </div>
</template>
<script setup lang="ts">
// name: MStarRating
interface IntStarRatingProps {
    value: number
}
const props = withDefaults(defineProps<IntStarRatingProps>(), {
    value: 0,
});
const activeStar = ref(Math.round(props.value));
</script>